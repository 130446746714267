import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PostCardCategoryList from "./PostCardCategoryList"
import PostCardDate from "./PostCardDate"

const Card = styled.div`
  border: 1px solid #f0f0f0;
  margin: 1em;

  @media (min-width: 768px) {
    width: 32%;
    margin: 0 10px 10px 0;
  }
`
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CardImage = styled.div`
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`

const CardLink = styled(Link)`
  text-decoration: none;
  color: #000;
`

const CardContent = styled.div`
  padding: 1em;
`

const CardTitle = styled.div`
  font-weight: bold;
  line-height: 2em;
  font-size: 1.2em;
`

const PostCard = ({ post }) => {
  return (
    <Card>
      <CardWrapper>
        <CardImage>
          <CardLink to={post.fields.slug}>
            <Img fluid={post.frontmatter.topImage.childImageSharp.fluid} />
          </CardLink>
        </CardImage>
        <CardContent>
          <PostCardCategoryList categories={post.frontmatter.categories} />
          <PostCardDate
            created_at={post.frontmatter.created_at}
            updated_at={post.frontmatter.updated_at}
          />
          <CardLink to={post.fields.slug}>
            <CardTitle>{post.frontmatter.title}</CardTitle>
          </CardLink>
        </CardContent>
      </CardWrapper>
    </Card>
  )
}

export default PostCard
