import React from "react"
import styled from "styled-components"

const Container = styled.h1`
  font-size: 2em;
  font-weight: 900;
  margin-left: 0.5em;
  @media (min-width: 768px) {
    margin-left: 0;
  }
`
function Title({ title }) {
  return <Container>{title}</Container>
}

export default Title
