import React from "react"
import styled from "styled-components"
import PostCard from "./PostCard"

const List = styled.div`
  margin: 2em 0;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const PostList = ({ posts }) => {
  return (
    <List>
      {posts.allMarkdownRemark.edges.map(({ node }) => {
        return <PostCard key={node.fields.slug} post={node} />
      })}
    </List>
  )
}

export default PostList
