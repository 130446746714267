import React from "react"
import Layout from "../components/layout/layout"
import Title from "../components/layout/Title"
import PostList from "../components/post/PostList"
import { graphql } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 40px;
  @media (min-width: 768px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`

const Blog = ({ data }) => {
  return (
    <div>
      <Layout>
        <Container>
          <Title title="ブログ記事一覧" />
          <PostList posts={data} />
        </Container>
      </Layout>
    </div>
  )
}

export default Blog

export const query = graphql`
  {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___created_at }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            created_at
            updated_at
            categories
            topImage {
              relativePath
              childImageSharp {
                id
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
